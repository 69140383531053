import { template as template_9473eb4b0a814c5fa4a96f0ae606666e } from "@ember/template-compiler";
const FKControlMenuContainer = template_9473eb4b0a814c5fa4a96f0ae606666e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
